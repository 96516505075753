import React from 'react';


class Wallet extends React.Component {

    render() {
        return (
            <div>

            </div>
        );
    }

}

export default Wallet ;


