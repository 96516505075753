import React from 'react';


class Wip extends React.Component {

    render() {
        return (
            <div>
            </div>
        );
    }

}

export default Wip ;


