import React from 'react';


class Recommend extends React.Component {

    render() {
        return (
            <div>

            </div>
        );
    }

}

export default Recommend ;


